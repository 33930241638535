/* button style */
.loadingButtonStyle1{
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #27272A;
    color: #27272A;
    border-radius: 4px;
    background-color: #fff;
    padding: 4px 16px;
    height: auto;
}

.loadingButtonStyle1:hover{
    background-color: #FCFBF8;
    border: 2px solid #27272A;
}

.loadingButtonStyle1:active{
    background-color: #EAE8E4;
    border: 2px solid #27272A;
}

.bgColor{
    background: #FCFBF8 0% 0% no-repeat padding-box;
}

.downloadBtn{
    padding: 0px;
    margin-right: 2.6px;
}

.noDataFound{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.noDataFoundHub {
    width: 100%;
    padding-left: 16px;
    font-size: 20px;
    font-weight: 700;
}

.loadingButtonStyle2 {
    font-size: 14px;
    font-weight: 600;
    border: 2px solid #29DE94;
    color: #27272A;
    border-radius: 4px;
    padding: 4px 16px;
    height: auto;
    background-color: #29DE94;
}

.loadingButtonStyle2:hover{
    background-color: #24C382;
    border: 2px solid #24C382;
}

.loadingButtonStyle2:active{
    background-color: #03B578;
    border: 2px solid #03B578;
    color: #fff;
}

.textCenter{
    text-align: center;
}

.imgMarginAuto{
    margin: auto;
}

/* wrapper page */
.sectionPadding{
    padding-top: 144px;
    padding-left: 16%;
    padding-right: 4%;
}

.sectionCommonPadding{
    padding-left: 16%;
    padding-right: 16%;
}

.sectionLeftPadding{
    padding-left: 16%;
}

.sectionRightPadding{
    padding-right: 16%;
}

.commonTopPadding{
    padding-top: 150px;
}

.commonPadding{
    padding-top: 150px;
    padding-bottom: 94px;
}

/*  */
.pointerStyle{
    background: #F4F1EA;
    border: 2px solid #27272A;
    width: 38px;
    height: 38px;
    text-align: center;
    border-radius: 50%;
    line-height: 37px;
    margin-right: 25px;
}

.pointerStyle span{
    font-size: 23px;
    font-weight: 600;
}

/* image */
.imageStyle{
    margin-top: -50px;
    margin-left: auto;
}

.imageMarginStyle{
    margin-top: -50px;
}

.everyThingHeadingStyle .subHeadingStyle h6{
    max-width: inherit;
}

.everyThingHeadingStyle .subHeadingStyle h5{
    max-width: 494px;
}

/* common css */
.flexDisplay{
    display: flex;    
}

.firstLetterCapital{
    text-transform: capitalize;
}

.marginBottom{
    margin-bottom: 40px;
}

.marginTopSm{
    margin-top: 30px;
}

.relativeParentDiv{
    position: relative;
}

.imgButton {
    padding: 0px;
    min-width: 0px;
    border: none;
    background: #fff;
    cursor: pointer;
}

.imgButton svg{
    margin-right: 15px;
    width: 24px;
    height: 24px;
}

.activeSvg{
    color: #0F9672;
}

/* common heading all hub pages */
.commonHeading{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #27272A;
}

.commonSubHeding{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #27272A;
    margin-bottom: 14px;
}

.commonText{
    font-size: 16px;
    color: #27272A;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
}

.borderNone .MuiDrawer-paper.MuiDrawer-paperAnchorLeft{
    border: none !important;
}

.mobileDivCenter{
    justify-content: center;
}

.titleStyle{
    letter-spacing: -0.28px;
    color: #27272A;
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
}

.margintitleStyle{
    margin-bottom: 16px;
}

.menuHeading h6{
    letter-spacing: 0px;
    color: #048B67;
    font-size: 16px;
    line-height: 24px;    
}

.topPadding{
    padding-right: 4%;
    padding-left: 4%;
}

.topCommonPadding{
    padding-top: 42px;
    padding-bottom: 33px;
}

.allReportStyle .descriptionHeading{
    margin-top: 0px;
    text-align: start;
}

.hubCardStyle.allReportStyle{
    text-align: start;
    height: 100px;
    cursor: pointer;
}

.hubCommonMargin{
    margin: 32px 0px;
}

.listViewStyle .hubCardStyle.allReportStyle{
    margin-top: 12px;
}

.hubListViewStyle{
    width: 100%;
    margin-left: 0px;
}

.hubListViewStyle .hubCardStyle.allReportStyle{
    height: auto;
}

/* download transition css style from here */
.subTextHeading{
    letter-spacing: 0px;
    color: #048B67;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;    
}

/* end here */
.topCalcPadding{
    padding-top: 20px;
}

/* report active sorting */
.sortingIcon{
    width: auto;
    display: flex;
    justify-content: end;
    align-items: center;
}

.sortingIcon .activeIcon{
    position: relative;
    opacity: 0;
    font-size: 16px;
    transform: rotate(0deg);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* calcultors home padding */
.homeCalcultorsStyle{
    padding: 110px 9% 0px;
}

.subCatStyle{
    margin-top: 16px;
}

.dataPrivacayHeading .subHeadingStyle h3{
    max-width: 670px;
}

.dataPrivacayHeading  .headingStyle h5, .addingSubHeading{
    max-width: 781px;
}

.dataPrivacayHeading  .headingStyle h5, .addingSubHeading{
    max-width: 781px;
}

.privacyDivStyle {
    border-radius: 10px;
    background: #fcfbf8;
    padding: 25px;
    width: 225px;
    margin-right: 20px;
    text-align: center;
}

.privacyDivStyle p{
    font-size: 46px;
    font-weight: bold;
    line-height: 52px;
    font-style: normal;
    text-align: center;
    color: #048b67;
}

.privacySubTxt{
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    font-style: normal;
    text-align: left;
    color: #27272a;
    max-width: 781px;
}

.privacyDivStyle span{
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    font-style: normal;
    text-align: center;
    color: #048b67;
}

.flexPrivacyDivStyle{
    display: flex;
    margin-right: 20px;
    align-items: center;
    margin-top: 44px;
    margin-bottom: 44px;
}

.dataPrivacyImg{
    width: 406.2px;
    object-fit: contain;
}

.boldText1Style {
    color: #27272a;
    font-weight: bold;
    text-decoration: underline;
}

.centerImgStyle{
    display: flex;
    justify-content: center;
}

.privacySpacing{
    padding-top: 0px;
    padding-bottom: 164px;
}

/* media query */
@media (max-width: 1660px) {
    .marginSmTop{
        margin-top: 10px;
    }
    .leftAlignText{
        justify-content: start !important;
    }
}

@media (max-width: 1200px) {
    .dataPrivacayHeading .subHeadingStyle h3{
        max-width: initial;
    }
    .dataPrivacayHeading .headingStyle h5, .addingSubHeading{
        max-width: initial;
    }
    .privacySubTxt{
        max-width: initial;
    }
    .imageMarginStyle, .imageStyle{
        margin-top: 0px;
    }
    .sectionPadding, .sectionCommonPadding{
        padding-top: 60px;
        padding-right: 9%;
        padding-left: 9%;
    }
    .sectionLeftPadding{
        padding-left: 9%;
    }
    .sectionRightPadding{
        padding-right: 9%;
    }
    .loadingButtonStyle1, .loadingButtonStyle2{
        font-size: 14px;
    }
    .divOverflow{
        overflow: hidden;
    }
    .everyThingHeadingStyle .subHeadingStyle h5{
        max-width: initial;
    }
    .commonTopPadding{
        padding-top: 120px;
        padding-bottom: 60px;
    }
    .commonPadding{
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .mdSectionMargin{
        margin-top: 10px;
    }
    .flexPrivacyDivStyle{
        display: block;
    }
    .privacyDivStyle {
        width: auto;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .dataPrivacyImg {
        width: 540px;
        margin-top: 40px;
    }
    .privacySpacing{
        padding-bottom: 64px;
    }
}

@media (max-width: 900px) {
    .sectionRightPadding{
        padding-right: 9%;
        padding-left: 9% !important;
    }   
    .divOverflow{
        overflow: hidden;
        padding-top: 0px;
    } 
    .homeCalcultorsStyle{
        padding: 110px 4% 0px;
    }
    .subCatStyle {
        margin-top: 16px;
    }
    .privacyDivStyle p {
        font-size: 32px;
    }
}

/* for ios mobile margin */
@media (max-width: 430px) {
    .extraMarginForMobile{
        margin-bottom: 76px;
    }
}

@media (max-width: 360px) {
    .pointerStyle span{
        font-size: 14px;
    }
    .pointerStyle{
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50%;
        line-height: 28px;
    }
    .pointerDivStyle {
        padding: 14px 12px;
    }
    .easyStepMargin {
        margin-top: -7rem;
    }  
    .pointerWidthStyle {
        max-width: 190px;
    }  
}

/* key dates and todos styles  */
.contentStyle {
    padding: 32px 16% 40px 4%;
}

.fullContentWidthStyle{
    padding: 20px 4%;
}

.titleBorderStyle {
    border-bottom: 2px solid #89A69F;
}

.headerDivStyle {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    background: #F8F8F8;
    border-radius: 10px;
    margin-top: 32px;
    overflow: hidden;
}

.headerTableStyle {
    width: 100%;
    border-radius: 10px;
    min-height: 480px;
    font-size: 16px;
    padding-bottom: 14px,
}

.headerTableStyle th {
    color: #27272A;
    font-size: 20px;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 24px;
    text-align: start;
    padding-bottom: 2px;
    font-weight: 700;
}

.headerTableStyle td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 24px;
    padding-left: 24px;
    position: relative;
}

.headerTableStyle p.Mui-error {
    color: rgb(255, 72, 66);
    font-size: 0.75rem;
    font-weight: 400;
    text-align: left;
    position: absolute;
    margin: 3px;
}

.inputStyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputStyle input {
    text-align: left;
}

.linkStyle {
    cursor: pointer;
    margin-right: 0px;
    color: #0F9672;
    font-weight: 400;
    line-height: 2.8;
    margin-bottom: 0px;
    background: transparent;
    border: none;
    box-shadow: none
}

.checkboxStyle {
    padding: 0px !important;
}

.datePickerStyle input {
    width: 122px;
    font-size: 14px;
    padding: 12px 0px 12px 12px;
}

.menuStyle ul {
    padding: 8px 6px !important;
    background: #F8F8F8;
    border: 1px solid #00773E;
    border-radius: 10px;
}

.menuStyle ul li {
    padding: 10px;
}

.taskStyle {
    display: flex;
    align-items: center;
    width: 240px
}

.autoCompleteStyle {
    font-size: 20px;
    position: relative;
    width: 170px !important;
}

.autoCompleteStyle .MuiInputBase-root {
    padding-right: 10px !important;
}

.autoCompleteStyle .MuiInputBase-root .Mui-focused::after {
    transform: scaleX(0) !important;
}

.autoCompleteStyle .MuiAutocomplete-clearIndicator {
    display: none;
}

.autoCompleteStyle .MuiAutocomplete-popupIndicator {
    position: relative;
    width: 25px;
}

.autoCompleteStyle .MuiAutocomplete-popupIndicator svg {
    display: none;
}

.autoCompleteStyle .MuiAutocomplete-popupIndicator::after {
    position: absolute;
    right: 0;
    top: -4px;
    content: '';
    background: url('../../../public/static/loginImg/down-arrow.svg') 0 0 no-repeat;
    width: 25px;
    height: 13px;
}

/* carousel Card Style here */

.carouselCardStyle{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
}

.carouselCardStyle img{
    width: 100%;
    height: 12vw;
    object-fit: cover;
    border-radius: 6px 6px 0px 0px;
}

.carouselDivStyle{
    padding: 24px 21px;
    height: 216px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.carouselDivStyle h4{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #27272A;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.carouselBtnStyle{
    background: #F5F1E9;
    border-color: #F5F1E9;
    border-radius: 42px;
    font-size: 10px;
    font-weight: 500;
    color: black;
    padding: 2px 8px;
    width: fit-content;
}

.calServiceStyle{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calServiceStyle .descriptionHeading{
    margin-top: 0px;
}

/* middle border style */
.middleBorderStyle{
    position: absolute;
    top: 0px;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    bottom: 0px;
    border-color: #155041;
    border-right: 1px;
}

/* button style */
.buttonFlexStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonMarginStyle{
    margin-top: 32px;
}

/* multiple button center */
.backButtonShowStyle{
    position: absolute;
    width: 120px;
    top: 46px;
}

.contactUsStyle label{
    position: initial;
}

.centerAlignStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loaderGif{
    width: 180px;
    margin: auto;
}

/* media query */
@media (max-width: 1536px) {
    .headerTableStyle h6 {
        font-size: 20px
    }
    .autoCompleteStyle input {
        min-width: 170px !important;
    }
    .headerDivStyle {
        overflow-x: auto;
    } 
}

@media (max-width: 1200px) {   
    .contentStyle {
        padding: 40px 4%;
    }
    .headerDivStyle{
        margin-top: 0px;
    }
    .autoCompleteStyle{
        padding-bottom: 0px;
    }
    .autoCompleteStyle input{
        font-size: 14px;
    }
}

@media (max-width: 1024px) {   
    .mobileOverFlowDiv{
        overflow: auto;
    }
}

@media (max-width: 900px) {
    .headerTableStyle {
        padding: 10px;
        font-size: 12px;
        min-width: 500px;
    }
    .headerTableStyle th {
        padding: 5px;
        font-size: 14px
    }

    .headerTableStyle td {
        padding: 5px;
        font-size: 12px
    }

    .tableOverflowStyle {
        overflow: auto;
    }
}

@media (max-width: 599px) {
    .commonText{
        font-size: 14px;
    }
    .titleStyle{
        font-size: 20px;
        line-height: inherit;
    }
    .commonHeading{
        font-size: 18px;
        padding: 0px !important;
    }
    .backButtonShowStyle{
        width: 100%;
        position: initial;
    }
    .overFlowTable{
        overflow: auto;
    }
}
/* key dates and to dos style ends here  */
.error-text {
    color: red;
    font-size: 14px;
    font-weight: 600;
}
.why-love-mm-dialog-padding {
    padding: 0 20px;
}

.why-love-mm-dialog-padding ul ul {
    padding-left: 40px;
}
.more-details-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}
.resend-approval-link {
    text-decoration: none;
    color: #007185;
}
.resend-approval-link-disabled {
    pointer-events: none;
    opacity: 0.5;
}
#timer {
    display: flex;
    padding: 10px 0;
}
#timer p {
    padding-left: 5px;
}
.security-points {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #f4f1ea;
}

.sectionPaddingBanner {
    padding-left: 16%;
    padding-right: 4%;
}
@media (max-width: 1200px) {
    .sectionPaddingBanner, .sectionCommonPadding {
        padding-right: 9%;
        padding-left: 9%;
    }
}

.onboardingWarningText {
    font-weight: bold;
    color: red;
    text-align: center;
}